@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nabla&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Silkscreen&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

i {
  justify-content: center;
  align-items: center;
  margin: auto
}

#silk {
  font-family: 'Silkscreen'
}

#blinking {
   
  animation: opacity 2s ease-in-out infinite alternate;
  margin-top: -3em;
}

@keyframes opacity {
0% {
  opacity: 1;
}

50% {
  opacity: 0.5;
}

100% {
  opacity: 0;
}
}

.scrolling-text {
  white-space: nowrap;
  overflow: hidden;
  animation: scrollText 10s linear infinite;
}

@keyframes scrollText {
  0% {transform: translateX(80%)}
  100% {transform:translateX(-80%)}
}

.w {
  overflow: hidden;
}